<template>
  <div class="importForm">
    <import-template
      style="box-sizing: border-box; height: 100%"
      :uploadImgUrl="uploadImgUrl"
      :downloadUrl="downloadUrl"
    ></import-template>
  </div>
</template>

<script>
import importTemplate from "@/components/bussiness/import-template/index";
import { getBuriedPointUrl, kindsListUrl } from "./api";
import {} from "./map";
export default {
  name: "importForm",
  data() {
    let _this = this;
    return {
      downloadUrl: "/static/excel/设备导入模板.xlsx",
      uploadImgUrl: "/deviceApi/iot/device/import",
    };
  },
  components: {
    importTemplate,
  },
  mounted() {
    const { id, communityId } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id: id });
    } else {
    }

    this.$setBreadList(id ? "编辑" : "产品导入");
  },
  beforeDestroy() {},
  methods: {
    // async getKindsList() {
    //   this.kindsList = [];
    //   let res = await this.$axios.get(`${kindsListUrl}`);
    //   if (res.code === 200) {
    //     if (res.data) {
    //       res.data.forEach((item) => {
    //         item.label = item.title;
    //         item.value = item.id;
    //         this.kindsList.push(item);
    //       });
    //     }
    //   }
    // },

    // async getBuriedPointList() {
    //   this.buriedPointList = [];
    //   let res = await this.$axios.get(`${getBuriedPointUrl}`);
    //   if (res.code === 200) {
    //     if (res.data) {
    //       res.data.forEach((item) => {
    //         item.label = item.typeName;
    //         item.value = item.id;
    //         this.buriedPointList.push(item);
    //       });
    //     }
    //   }
    // },

    submitSuccess(data) {
      return true;
    },

    submitBefore() {
      return true;
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.importForm {
  box-sizing: border-box;
  height: 100%;
}
</style>
<style scoped></style>
