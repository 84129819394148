//物联网设备列表
const getDeviceList = `/gateway/hc-device/iot/device/list`;
//物联网产品列表
const getDeviceProList = `/gateway/hc-device/iot/product/list`;
//新增物联网设备
const addDeviceList = `/gateway/hc-device/iot/device/submit`;
//获取设备凭证
const getDeviceCredentials = (url) => {
  return `/gateway/hc-device/iot/device/${url.deviceId}/credentials`;
};
//删除设备
const deleteDeviceUrl = (url) => {
  return `/gateway/hc-device/iot/device/${url.deviceId}/delete`;
};
//设备详情
const getDeviceInfoUrl = (url) => {
  return `/gateway/hc-device/iot/device/${url.deviceId}/detail`;
};
//查设备序列详情
const getDeviceCodeUrl = `/gateway/hc-connect/data/queryReportData`;
//远程控制
const remoteControlUrl = (url) => {
  return `/gateway/hc-device/iot/device/${url.deviceNum}/remoteControl`;
};
export {
  getDeviceList,
  addDeviceList,
  getDeviceCredentials,
  deleteDeviceUrl,
  getDeviceInfoUrl,
  getDeviceProList,
  getDeviceCodeUrl,
  remoteControlUrl,
};
