import { mapHelper } from "@/utils/common.js";
const isOPen = [
  {
    value: false,
    label: "关闭",
  },
  {
    value: true,
    label: "开启",
  },
];
const { map: isOPenMap, setOps: isOPenOps } = mapHelper.setMap(isOPen);

export { isOPenMap, isOPen };
